import React, { useEffect, useState } from "react";
// import Header from "../layout/Header";
// import Sidebar from "../layout/Sidebar";
import axios from "axios";
import { Link, useNavigate,useLocation  } from "react-router-dom";
import { baseUrl } from "../../config/baseUrl";
// import { ToastMessgae } from "../utils/toast";
//  https://admin.cleaningcrm.fluttertrends.com/module/mobile/report-viewset/
import Swal from "sweetalert2";
import Pagination from "../Pagination/Pagination";
import { ToastMessgae } from "../utils/toast";
export default function ReportList() {
  let [reportData, setReportData] = useState([]);
  // let [reportDatafilter, setReportDataFilter] = useState([]);
  let [onLoader, setOnLoader] = useState(true);
  // let [filterDate, setFilterDate] = useState("");
  // let [pageNumber, setPageNumber] = useState(1);
  let [pageLimit, setPageLimit] = useState(1);
  let Navigate = useNavigate();
  let [depoData, setDepoData] = useState([]);
  let [companyNameData, setCompanyNameData] = useState([]);
  // let [lostPorperty, setLostProperty] = useState(false);
  let loginUser = JSON.parse(localStorage.getItem("user"));
  const location = useLocation();
  const data = location.state;
  
  const [paginationDetails, setPaginationDetails] = useState({});
  let [pageNumber, setPageNumber] = useState(data?data.pageNumber : 1);
  let [searchData, setSearchData] = useState(data ? data.searchData :{
    company: "",
    depo: "",
    start_date: "", 
    end_date: "", 
  });

  // console.log(data);
  // if(data){
  //   // console.log("data ENter");
    
    
  // }

  const reportListApi = async () => {
    try {
      let response = await axios(
        `${baseUrl}/module/mobile/report-viewset?page=${pageNumber}&company=${searchData?.company}&depo=${searchData.depo}&start_date=${searchData.start_date}&end_date=${searchData.end_date}`,
        {
          method: "GET",
          headers: {
            Authorization: "Token " + localStorage.getItem("token"),
          },
        }
      );
      if (response.data.code === 404) {
        if (pageNumber > 1) {
          setPageNumber(pageNumber - 1);
          return alert("page not field");
        }
      }
      setReportData(response?.data?.data ||[]);
      setPaginationDetails(response?.data?.extra);
      setPageLimit(response.data.extra.total);
      setOnLoader(false);
    } catch (error) {
      alert(error.message + ", Some error form sever");
      Navigate("/");
    }
  };
  useEffect(() => {
    reportListApi();
  }, [pageNumber]);

  let deleteHandle = (id) => {
    let apiCall = async () => {
      let response = await axios(
        `${baseUrl}/module/mobile/report-viewset/${id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: "Token " + localStorage.getItem("token"),
          },
        }
      );
      console.log(response.data);
      
      setReportData((prvVal) => {
        prvVal = prvVal.filter((arr) => arr.id !== response?.data?.data?.id);
        return [...prvVal];
      });
      reportListApi()
    };
    Swal.fire({
      title: "Are you sure..?",
      text: `You won't be able to revert this..!`,
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes, delete it!`,
    }).then((result) => {
      if (result.isConfirmed) {
        apiCall();
        Swal.fire({
          title: "deleted...!",
          text: `Report has been delete..`,
          icon: "success",
        });
      }
    });
  };

  const depo = async () => {
    let response = await axios(`${baseUrl}/module/admin/depo-list`, {
      method: "GET",
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
      },
    });
    setDepoData(response?.data?.data || []);
  };
  // depo();
  const companyName = async () => {
    let response = await axios(
      // https://admin.cleaningcrm.fluttertrends.com/module/admin/company-list
      `${baseUrl}/module/admin/company-list`,
      {
        method: "GET",
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
        },
      }
    );
    // console.log(response.data);
    setCompanyNameData(response.data?.data);
    //  setPageLimit(response?.data?.extra?.total);
  };

  useEffect(() => {
    depo();
    companyName();
  }, []);

  let filterHandler = async (e) => {
    setSearchData((curr) => {
      return { ...curr, [e.target.name]: e.target.value };
    });
  };

  let handleSearch = async (e) => {
    // console.log(searchData);
    setPageNumber(1)
    e.preventDefault();
    try {
      let response = await axios(
        `${baseUrl}/module/mobile/report-viewset?company=${searchData?.company}&depo=${searchData.depo}&start_date=${searchData.start_date}&end_date=${searchData.end_date}`,
        {
          method: "GET",
          headers: {
            Authorization: "Token " + localStorage.getItem("token"),
          },
          // data: searchData,
        }
      );
      console.log(response.data);
      if (response.data.code === 200) {
        setReportData(response.data.data || []);
        setPageLimit(response.data.extra.total);
        return;
      } else if (response.data.code === 404) {
        ToastMessgae(response.data);
      } else if (response.data.code === 400) {
        ToastMessgae(response.data);
      }
    } catch (error) {
      alert("some error from Backend");
    }
  };

  return (
    <>
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                {/* <i className="fa fa-chart-bar me-2" /> */}
                <i className="fa fa-flag me-2" />
                Report List
              </h4>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Report
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cards bus-list">
              <div className="bus-filter">
                <div>
                  <span> &nbsp;</span>
                  {/* <button className="btn btn-outline-primary" onClick={DownloadCSVFile} >
                  <i class="fa fa-download"  ></i> 
                  </button> */}
                  <Link
                    // to={`${baseUrl}/module/admin/download-report-excel/${loginUser.company || 0}`}
                    to={`${baseUrl}/module/admin/download-report-excel/${
                      loginUser.id || 0
                    }?company=${searchData?.company}&depo=${searchData.depo}&start_date=${searchData.start_date}&end_date=${searchData.end_date}`}
                    // target="_blank"
                    className="btn btn-primary"
                  >
                    <i className="fa fa-download  me-2"></i> CSV
                  </Link>
                </div>
                <div className="row justify-content-end">
                  <div className="col-lg-8">
                    <div className="row justify-content-end">
                      {loginUser.user_type === "SUPER_ADMIN" && (
                        <div className="col ">
                          <select
                            name="company"
                            value={searchData.company}
                            onChange={filterHandler}
                            className="form-select"
                          >
                            <option value="">Select Company</option>
                            {companyNameData?.map((arr) => (
                              <option value={arr?.id} key={arr?.id}>
                                {arr?.company_name}
                              </option>
                            ))}
                          </select>
                        </div>
                      )}
                      <div className="col ">
                        <select
                          name="depo"
                          onChange={filterHandler}
                          className="form-select"
                          value={searchData.depo}
                        >
                          <option value="">Select Depo</option>
                          {depoData?.map((arr) => (
                            <option key={arr?.id} value={arr?.id}>
                              {arr?.depo_name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col  ">
                        <input
                          type="date"
                          name="start_date"
                          className="form-control"
                          onChange={filterHandler}
                          value={searchData.start_date}
                        />
                      </div>
                      <div className="col">
                        <input
                          type="date"
                          name="end_date"
                          className="form-control"
                          onChange={filterHandler}
                          value={searchData.end_date}
                        />
                      </div>
                      <div className="col ">
                        <button
                          className="thm-btn w-100"
                          onClick={handleSearch}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="table table-responsive custom-table">
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th>
                        {/* <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue=""
                              id="checkAll"
                            />
                          </div> */}
                        Sr. no.
                      </th>
                      <th>Date</th>
                      <th>Time</th>
                      <th>Location</th>
                      <th>Name Cleaner</th>
                      <th>Depo Name</th>
                      <th>Bus Number</th>
                      <th>company</th>
                      <th>Desc of Work</th>
                      <th>cleaning type</th>
                      {/* <th>Issue Name</th> */}
                      {/* <th>Issue Images</th> */}
                      {/* <th></th> */}
                      {/* <th>Signature Cleaner</th> */}
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reportData.length !== 0 ? (
                      reportData?.map((arr, i) => (
                        <tr className="red-color-report" key={arr?.id}>
                          <td>
                            {/* <div className="form-check">
                              <input
                                className="form-check-input checkbox"
                                type="checkbox"
                                defaultValue=""
                                id="check"
                              />
                            </div> */}
                            {++i + 10 * (pageNumber - 1)}
                          </td>
                          <td>
                            <span className="text-warning">{arr?.date}</span>
                          </td>
                          <td>{arr?.time?.split(".")[0]}</td>
                          <td>{arr?.location}</td>
                          <td>{arr?.name_cleaner}</td>
                          <td>{arr?.depo?.depo_name}</td>
                          <td>{arr?.bus_number}</td>
                          <td>{arr?.company?.company_name}</td>
                          <td>{arr?.desc_of_work}</td>
                          <td
                            className={(`${arr?.cleaning_type}` ===
                            "ANNUAL_DEEP_CLEANING"
                              ? "bg-danger text-center"
                              : `${arr?.cleaning_type}` ===
                                "STANDARD_DEEP_CLEANING"
                              ? "bg-warning text-center"
                              : `${arr?.cleaning_type}` ===
                                  "REGULAR_CLEANING" &&
                                `${arr?.issues.length}` > 0
                              ? "bg-success text-center"
                              : "text-center"
                            ).toString()}
                          >
                            {" "}
                            {arr?.cleaning_type}
                          </td>
                          {}
                          <td>
                            <Link
                              className="btn btn-outline-info btn-sm rounded-circle btn-sm mx-1 "
                              to={`/report-view/${arr.id}`}  // Path as string
                              state={{
                                pageNumber: pageNumber,
                                searchData: searchData,
                              }}  
                            >
                              <i className="fa fa-eye" />
                            </Link>
                            {/* <Link
                              className="btn btn-outline-info btn-sm rounded-circle btn-sm mx-1 "
                              to={`/report-view/${arr.id}`}
                            >
                              <i className="fa fa-eye" />
                            </Link> */}
                            
                           {loginUser?.user_type === "CLIENT" || loginUser?.user_type ==="SUPER_ADMIN" ? null: <Link
                              to="#"
                              onClick={() =>   deleteHandle(arr?.id)}
                              className="btn btn-outline-danger rounded-circle btn-sm"
                            >
                              <i className="fa fa-trash" />
                            </Link>}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <th className="py-2">Data Not Found</th>
                    )}
                  </tbody>
                </table>
                {/* <div>
                      <nav aria-label="Page navigation example">
                        <ul className="pagination">
                          <li className="page-item"  onClick={() => setPageNumber((prev) => prev - 1)}>
                            <Link className="page-link" to="#">
                              Previous
                            </Link>
                          </li>
                           <li className="page-item active">
                            <Link className="page-link" to="#">
                            {pageNumber}
                            </Link>
                          </li> 
                          <li className="page-item "  onClick={() => setPageNumber((prev) => prev + 2)}>
                            <Link className="page-link" to="#">
                             {pageNumber+1}
                            </Link>
                          </li>
                          <li className="page-item"  onClick={() => setPageNumber((prev) => prev + 2)}>
                            <Link className="page-link" to="#">
                           { pageNumber+2}
                            </Link>
                          </li>
                          <li className="page-item"  onClick={() => setPageNumber((prev) => prev + 1)}>
                            <Link className="page-link" to="#" >
                              Next
                            </Link>
                          </li>
                        </ul>
                      </nav>
                    </div> */}
                <Pagination
                  pageNumber={pageNumber}
                  setPageNumber={setPageNumber}
                  pageLimit={pageLimit}
                  paginationDetails={paginationDetails}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
