import React, { useEffect, useState } from "react";
import Header from "../layout/Header";
import Sidebar from "../layout/Sidebar";
import { Link, useParams ,useLocation} from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../config/baseUrl";
export default function ReportView() {
  let [reportData, setReportData] = useState({});
  const [repotImg, setRepotImg] = useState({img:"",index:"",filter:""});
  const [images, setImages] = useState([]);
  const [issue, setIssue] = useState([]);
  const [postimages, setPostImages] = useState([]);
  let { id } = useParams();
  const location = useLocation();
  const prvpagedata = location.state;
  console.log(location);
  
  // console.log(prvpagedata);
  
  useEffect(() => {
    const report = async () => {
      let response = await axios(
        `${baseUrl}/module/mobile/report-viewset/${id}`,
        {
          method: "GET",
          headers: {
            Authorization: "Token " + localStorage.getItem("token"),
          },
        }
      );
      // console.log(response.data.data)
      setReportData(response?.data?.data || []);
    };
    report();

    const issue_images = async () => {
      let response = await axios(
        `${baseUrl}/module/mobile/images_issues/${id}`,
        {
          method: "GET",
          headers: {
            Authorization: "Token " + localStorage.getItem("token"),
          },
        }
      );
      console.log(response.data)
      // console.log(response.data.issues)
      setImages(response?.data?.data?.images || [])
      setIssue(response?.data?.data?.issues || [])
      setPostImages(response?.data?.data?.postimages || [])
      // setReportData(response?.data?.data);
      // setDepoData(response?.data?.data);
    };
    issue_images();

  }, []);
  let changeImg=( slide)=>{
     let index=repotImg.index
     let imgCleaning=repotImg.filter
     if(slide == "prv"){
      --index
     }else{
      index++
     }
     if(imgCleaning== "pre"){
       let data=images.filter((arr,i)=>(i === index))
       if(data.length === 0 ){
        return
       }
       setRepotImg((curlVal)=>({...curlVal,img:data[0].images,index:index}))
     }
     if(imgCleaning== "post"){
      let data=postimages.filter((arr,i)=>(i === index))
       if(data.length === 0 ){
        return
       }
        setRepotImg((curlVal)=>({...curlVal,img:data[0].postImages,index:index}))
     }
     if(imgCleaning== "isuueImg"){
      let data=issue.filter((arr,i)=>(i === index))
       if(data.length === 0 ){
        return
       }
        setRepotImg((curlVal)=>({...curlVal,img:data[0].issue_images,index:index}))
     }
  }




  return (
    <>
      {/* <Header /> */}
      {/* <Sidebar activeItem={"ReportManagement"} /> */}
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fa fa-flag me-2" />
                Report Details
              </h4>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Report Detail
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cards">
              <div className="row justify-content-center">
                <div className="col-lg-8">
                  <div className="cards report-dtl">
                    <table className="table table-bordered dtl-table">
                      <tbody>
                        <tr>
                          <th>ID</th>
                          <td>{reportData?.id}</td>
                        </tr>
                        <tr>
                          <th>Cleaner Name</th>
                          <td>{reportData?.name_cleaner}</td>
                        </tr>
                        <tr>
                          <th>Pre-Cleaning Photos</th>
                          <td>
                            <div className="table-img-box">
                              {
                                images?.map((img,i)=>(
                              <button   key={i}>
                                <img
                                  src={`${img?.images}`}
                                  alt="reportImg"
                                  data-bs-toggle="modal"
                                  data-bs-target="#reportImg"
                                  onClick={() =>
                                    setRepotImg({img:img?.images,index:i,filter:"pre"})
                                  }
                                />
                              </button>
                                ))
                              }

                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th>Post-Cleaning Photos </th>
                          <td>
                            <div className="table-img-box">
                              {
                                postimages?.map((img,i)=>(
                              <button  key={i}>
                                <img
                                  src={`${img?.postImages}`}
                                  alt="reportImg"
                                  data-bs-toggle="modal"
                                  data-bs-target="#reportImg"
                                  onClick={() =>
                                    // setRepotImg(img?.postImages)
                                    setRepotImg({img:img?.postImages,index:i ,filter:"post"})
                                  }
                                />
                              </button>
                                ))
                              }
                              
                              {/* <a href="#">
                                <img
                                  src={`${reportData?.images}`}
                                  alt="reportImg"
                                  data-bs-toggle="modal"
                                  data-bs-target="#reportImg"
                                  onClick={() =>
                                    setRepotImg(reportData?.issue_images)
                                  }
                                />
                              </a>
                              <a href="#">
                                <img
                                  src={`${reportData?.images}`}
                                  alt="reportImg"
                                  data-bs-toggle="modal"
                                  data-bs-target="#reportImg"
                                  onClick={() =>
                                    setRepotImg(reportData?.issue_images)
                                  }
                                />
                              </a>
                              <a href="#">
                                <img
                                  src={`${reportData?.images}`}
                                  alt="reportImg"
                                  data-bs-toggle="modal"
                                  data-bs-target="#reportImg"
                                  onClick={() =>
                                    setRepotImg(reportData?.issue_images)
                                  }
                                />
                              </a> */}
                            </div>
                          </td>
                        </tr>
                        {/* <tr>
                          <th>issue</th>
                          <td>{reportData?.issue_name}</td>
                        </tr> */}
                        <tr>
                          <th>Location</th>
                          <td>{reportData?.location}</td>
                        </tr>
                        <tr>
                          <th>Work Description</th>
                          <td>{reportData?.desc_of_work}</td>
                        </tr>
                        <tr>
                          <th>Date/Time</th>
                          <td>
                            {reportData?.date}/{" "}
                            {reportData?.time?.split(".")[0]}
                          </td>
                        </tr>
                        <tr>
                          <th>Issue Images</th>
                          <td>
                            <div className="table-img-box">
                              {issue?.map((arr,i)=>(
                                <button key={i}>
                                <img
                                  src={`${arr?.issue_images}`}
                                  alt="reportImg"
                                  data-bs-toggle="modal"
                                  data-bs-target="#reportImg"
                                  onClick={() =>
                                    // setRepotImg(arr?.issue_images)
                                    setRepotImg({img:arr?.issue_images,index:i ,filter:"isuueImg"})
                                  }
                                  />
                                  <h6>{arr?.issue_name}</h6>
                              </button>
                              ))}
                              
                              {/* <a href="#">
                                <img
                                  src={`${reportData?.issue_images}`}
                                  alt="reportImg"
                                  data-bs-toggle="modal"
                                  data-bs-target="#reportImg"
                                  onClick={() =>
                                    setRepotImg(reportData?.issue_images)
                                  }
                                />
                              </a>
                              <a href="#">
                                <img
                                  src={`${reportData?.issue_images}`}
                                  data-bs-toggle="modal"
                                  data-bs-target="#reportImg"
                                  onClick={() =>
                                    setRepotImg(reportData?.issue_images)
                                  }
                                  alt="reportImg"
                                />
                              </a>
                              <a href="#">
                                <img
                                  src={`${reportData?.issue_images}`}
                                  data-bs-toggle="modal"
                                  data-bs-target="#reportImg"
                                  onClick={() =>
                                    setRepotImg(reportData?.issue_images)
                                  }
                                  alt="reportImg"
                                />
                              </a> */}
                            </div>
                          </td>
                        </tr>
                        {/* <tr>
                          <th>Signature</th>
                          <td>
                            <img
                              src={`${reportData?.signature_cleaner}`}
                              alt="reportImg"
                              className="sig-img"
                              data-bs-toggle="modal"
                              data-bs-target="#reportImg"
                              onClick={() =>
                                setRepotImg(reportData?.issue_images)
                              }
                            />
                          </td>
                        </tr> */}
                        <tr>
                          <th>Company Name</th>
                          <td>{reportData?.company?.company_name}</td>
                        </tr>
                        <tr>
                          <th>Depot Name</th>
                          <td>{reportData?.depo?.depo_name}</td>
                        </tr>
                      </tbody>
                    </table>
                    {/* <button>exit</button> */}
                    <div className="col-lg-12 text-center">
                    <Link
                    // to="/report-list"
                    to={`/report-list`}  // Path as string
                    state={prvpagedata}
                    // data
                      type="Submit"
                      className="thm-btn"
                      disabled 
                    >
                      <i className="fa fa-chevron-left" /> &nbsp;
                      back
                    </Link>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        className="modal fade"
        id="reportImg"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <img src={`${repotImg.img}`} alt="img" />
              <div className="btn-containermodel ">
              <button className="btn btn-info mt-3" onClick={()=>changeImg("prv")}>Previous </button>
              <button className="btn btn-success mt-3"  onClick={()=>changeImg("next")}> Next </button>
              </div>
            </div>
            {/* <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="button" className="btn btn-primary">
                <a href={barcodeValue}  target="_blank" download  >
                Download Now
                </a>
              </button>
            </div> */}
          </div> 
        </div>
      </div>
    </>
  );
}
